import { createGlobalStyle } from "styled-components";
/**
 * AnimationStyles Component
 * Exports all rendered styles
 */
const AnimationStyles = createGlobalStyle`
  body {
    transition: background-color 1s ${({ theme }) =>
      theme.animation.timingFunction.css}
  }

  body.page-tourred-room {
    background: #4F1313;

    .nav:after {
      background: linear-gradient(180deg, #4F1313 0%,rgba(15,39,7,0) 100%);
    }
  }

  body.page-inquire {
    [data-cy="nav-menu-button"] {
      display: none;
      pointer-events: none;
    }
  }
`;

export default AnimationStyles;
