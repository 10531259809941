import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={112}
    height={68}
    viewBox="0 0 112 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110 34c0-8.332-5.812-16.061-15.612-21.763C84.613 6.55 71.043 3 56 3s-28.613 3.55-38.388 9.237C7.812 17.939 2 25.668 2 34c0 8.332 5.812 16.061 15.612 21.763C27.387 61.45 40.957 65 56 65s28.613-3.55 38.388-9.237C104.188 50.061 110 42.332 110 34ZM56 0C40.662 0 26.728 3.728 16.595 9.808 6.48 15.876 0 24.398 0 34s6.481 18.124 16.595 24.192C26.728 64.272 40.662 68 56 68c15.338 0 29.272-3.728 39.405-9.808C105.52 52.124 112 43.602 112 34s-6.481-18.124-16.595-24.192C85.272 3.728 71.338 0 56 0Z"
      fill="#F5D171"
    />
    <path
      d="M48.685 50C53.838 40.4 66.052 35.286 72 35.286v-2.571c-5.948 0-18.396-5.115-23.315-14.715H46v32h2.685Z"
      fill="#F5D171"
    />
  </svg>
);

export default SvgComponent;
