import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import Link from "../john-gatsby-helpers/link";
import SvgLoader from "../svg-loader";

export function NavLogo({ src, hidden }) {
  return (
    <LinkWrap
      to={src}
      aria-label="Long Beach Petroleum Club"
      aria-hidden={hidden}
      className="logo-nav"
    >
      <NavLogoStyles />
      <LogoWrap>
        <FullLogo color="golden" logo="FullLogo" data-show-mobile="false" />
        <Monogram color="golden" logo="Monogram" data-show-mobile="true" />
      </LogoWrap>
    </LinkWrap>
  );
}

const LinkWrap = styled(Link)`
  pointer-events: visible;
`;

const LogoWrap = styled.span`
  position: relative;
  display: inline-block;
  width: 2.4375rem;
  height: 3rem;

  @media ${({ theme }) => theme.device.tablet} {
    width: 10.25rem;
    height: 3.4375rem;
  }
`;

const FullLogo = styled(SvgLoader)`
  position: absolute;
  top: 0;
`;

const LineLogo = styled(SvgLoader)`
  position: absolute;
  top: 0;
  left: 0.0625rem;
`;

const Monogram = styled(SvgLoader)`
  position: absolute;
  top: 0;
`;

const NavLogoStyles = createGlobalStyle`
  body {

    ${LogoWrap} {
      transform: none;
      transition: transform ${({ theme }) =>
        theme.animation.duration[100].css} ;
      transition-timing-function: ${({ theme }) =>
        theme.animation.timingFunction.css};
    }

    ${FullLogo},
    ${LineLogo},
    ${Monogram} {
      transition: opacity ${({ theme }) => theme.animation.duration[100].css} ;
      transition-timing-function:
        ${({ theme }) => theme.animation.timingFunction.css};
    }

    ${LineLogo} {
      opacity: 0;
    }

    &.page-inquire {
      ${LogoWrap} {
        transform: translate3d(0, 1.1rem, 0);
      }

      ${LineLogo} {
        opacity: 1;
      }

      ${FullLogo} {
        opacity: 0;
      }

      ${Monogram} {
        visibility: hidden;
        display: none;
      }

      .nav {
        position: fixed;
      }

      nav {
        visibility: hidden;
        opacity: 0;
        transform: translate3d(0, -1rem, 0);
        transition:
          transform ${({ theme }) => theme.animation.duration[100].css},
          opacity ${({ theme }) => theme.animation.duration[100].css},
          visibility 0ms ${({ theme }) => theme.animation.timingFunction.css}
            ${({ theme }) => theme.animation.duration[100].css};
      }
    }
  }
`;
