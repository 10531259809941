import { clampBuilder } from "../functions/util";

/**
 * size const
 *
 * All screen sizes in pixel value
 */
export const size = {
  mobileSm: "320px",
  mobile: "375px",
  mobileLg: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopLg: "1440px",
  desktop: "1920px",
  desktopLg: "2560px",
};

/**
 * device const
 *
 * All device '@media' queries sizes in pixel value
 */
export const device = {
  mobileSm: `(min-width: ${size.mobileSm})`,
  mobile: `(min-width: ${size.mobile})`,
  mobileLg: `(min-width: ${size.mobileLg})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopLg: `(min-width: ${size.laptopLg})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopLg: `(min-width: ${size.desktopLg})`,
};

/**
 * colors const
 *
 * All colors
 */
export const colors = {
  paper: "#FFF8E5",
  black: "#0F2707",
  darkBlack: "#091A04",
  gray: "rgba(15, 39, 7, 0.8)",
  grayLight: "rgba(15, 39, 7, 0.4)",
  evergreen: "#223E19",
  golden: "#F5D171",
  red: "#D81F00",
  white: "#ffffff",
};

/**
 * colorPlates const
 *
 * All colors
 */
export const colorPlates = {
  black: {
    foreground: colors.golden,
    background: colors.black,
    border: colors.golden,
    icon: "golden",
  },
  blackOutline: {
    foreground: colors.golden,
    background: "transparent",
    border: colors.golden,
    icon: "golden",
  },
  golden: {
    foreground: colors.black,
    background: colors.golden,
    border: colors.black,
    icon: "black",
  },
  paper: {
    foreground: colors.black,
    background: colors.paper,
    border: colors.black,
    icon: "black",
  },
  nude: {
    foreground: colors.golden,
    background: "transparent",
    border: "transparent",
    icon: "golden",
  },
};

/**
 * fonts const
 */
export const fonts = {
  h1: clampBuilder(48 / 16, 108 / 16),
  h2: clampBuilder(40 / 16, 72 / 16),
  h3: clampBuilder(32 / 16, 64 / 16),
  h4: clampBuilder(24 / 16, 48 / 16),
  h5: clampBuilder(20 / 16, 32 / 16),
  h6: clampBuilder(13.7 / 16, 13.7 / 16),
  titleLarge: "0.75rem",
  title: "0.625rem",
  titleSmall: "0.53125rem",
  paragraphLarge: "1.375rem",
  paragraph: "1rem",
  paragraphSmall: "0.75rem",
};

/**
 * Common
 */
export const common = {
  border: `solid ${colors.black} ${clampBuilder(1.5 / 16, 3 / 16)}`,
};

/**
 * animation const
 *
 * All animation variables
 */
export const animation = {
  timingFunction: {
    js: [0.12, 0.49, 0.27, 0.94],
    css: `cubic-bezier(.12,.49,.27,.94)`,
  },
  duration: {
    100: {
      js: 0.3,
      css: `300ms`,
    },
    200: {
      js: 0.5,
      css: `500ms`,
    },
    300: {
      js: 0.6,
      css: `600ms`,
    },
    400: {
      js: 0.7,
      css: `700ms`,
    },
    500: {
      js: 0.8,
      css: `800ms`,
    },
  },
};

/**
 * layout const
 *
 * All layout variables
 */
export const layout = {
  outerRailBase: "1.5rem",
  outerRailTablet: "7vw",
  outerRailDesktop: "10vw",
  space: {
    100: "1rem",
    200: "3rem",
    300: "5rem",
    400: "4vw",
    500: "8vw",
  },
};

export default {
  device,
  colors,
  colorPlates,
  animation,
  size,
  fonts,
  layout,
  common,
};
