import * as React from "react";

function CheckIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.11 12.022C12.607 9.028 15.8 6.007 20.59 5v3.02c-3.967.95-6.126 2.421-7.984 5.442-1.462 2.377-1.508 4.756-1.508 5.538H9c0-2.54-1.944-6-6-6v-3c2.76 0 5.12 2.17 6.098 4.163.523 1.066.743 1.85.835 2.32h.13c.142-1.332.44-3.246 1.048-4.461z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default CheckIcon;
