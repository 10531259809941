import { createGlobalStyle } from "styled-components";

const PrintStyles = createGlobalStyle`
  @media screen {
    .only-print {
      display: none;
    }
  }

  @media print {
    .footer--upper,
    .nav--left,
    .nav--right,
    .nav--mobile-menu {
      display: none;
    }

    *,
    svg {
      background: transparent !important;
      color: black !important;
      fill: black !important;
    }

    .image,
    .marquee-image,
    .background-image {
      display: none !important;
    }

    .nav {
      position: absolute;

      &:before {
        background-color: transparent !important;
        box-shadow: none !important;
      }

      &--logo {
        justify-content: center;

        .logo > svg {
          width: 16rem !important;
          margin-top: 2rem;
        }
      }
    }

    .page-marquee {
        height: 100vh !important;
        margin: 0;
    }

    .menu-panel {
        --height: unset !important;
        height: unset !important;
        opacity: 1 !important;
        transform: none !important;
    }

    .menu-item--panel {
        --row-gap: 1rem !important;
        margin: 1rem 4vw 0 !important;
        page-break-before: always;
        page-break-inside: avoid;
    }
    .menu-item--title {
        margin-bottom: 0rem !important;
        page-break-inside: avoid;
    }
    .menu-item--item-group {
        gap: 1rem !important;
    }
    .menu-item--row {
        page-break-before: always;
        page-break-inside: avoid;
    }

    .split-section {
        --row-gap: 0 !important;
        margin: 0 0 2rem !important;
    }
  }

  @media print {
    body {
      width: 100%;
      font-size: 10pt !important;

        .p-title {
            font-size: 14pt
        }
    }

    body, h1, h2, h3, ol, ul, div {
      width: auto;
      border: 0;
      margin: 0;
      padding: 0;
      float: none;
      position: static;
      overflow: visible;
    }

    .no-print, [data-no-print] {
      display: none !important;
    }

    .only-print, [data-only-print] {
      display: block;
    }

    #footer {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h1.h2 {
      font-size: 8rem;
    }

    .image-section {
        --row-gap: 0.5rem !important;
    }

    p.title-large,
    .p-title-large {
      font-family: 'DomaineSansTextBold';
      font-size: 1rem;
      line-height: 130%;
      letter-spacing: 0.4em;
      text-transform: uppercase;
    }

    p.title,
    .p-title {
      font-family: 'DomaineSansTextBold';
      font-size: .8rem;
      line-height: 130%;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }

    p.title-small,
    .p-title-small {
      font-family: 'DomaineSansTextBold';
      font-size: .6rem;
      line-height: 130%;
      letter-spacing: 0.4em;
      text-transform: uppercase;
    }

    p.large,
    .p-large {
      font-size: 1rem;
      line-height: 130%;
      letter-spacing: 0.01em;
    }

    p,
    .p {
      font-family: 'DomaineSansTextRegular';
      font-style: normal;
      font-size: .8rem;
      line-height: 130%;
      font-feature-settings: 'ss05' on, 'ss03' on, 'ss07' on, 'calt' off,
        'liga' off;

      &.strong {
        font-family: 'DomaineSansTextBold';
      }

      &.paragraph-block {
        line-height: 280%;
        white-space: pre-line;
      }
    }

    p.small,
    .p-small {
      font-size: .6rem;
      line-height: 130%;
      letter-spacing: 0.02em;
    }
  }
`;

export default PrintStyles;
