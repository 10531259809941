import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

/**
 * Local Components
 */
import Head from "./globals/head";
import Nav from "./globals/nav";
import Footer from "./globals/footer";

/**
 * Local styles
 */
import { PageWrapper } from "./layout-components";
import BackgroundHeader from "./background-header";
import useBackgroundImage from "../hooks/use-background";

/**
 * Code Highlighting styles
 */

/**
 * Layout Component
 *
 * @param {Object} props
 * @param {any} props.children
 * @param {Object} props.pageContext
 * @returns page layouts
 */
export default function Layout({ children, ...rest }) {
  if (typeof window !== "undefined") {
    if (CSS && "paintWorklet" in CSS)
      CSS.paintWorklet.addModule("https://unpkg.com/smooth-corners");
  }

  console.log(rest);

  const { marquee_background, marquee_background_red_room } =
    useBackgroundImage();

  return (
    <ParallaxProvider>
      <Head pageContext={rest} />
      <Nav pageContext={rest} />
      <PageWrapper id="main">{children}</PageWrapper>
      <Footer pageContext={rest} />
      <BackgroundHeader
        pageContext={rest}
        marquee_background={marquee_background}
        marquee_background_red_room={marquee_background_red_room}
      />
    </ParallaxProvider>
  );
}
