import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.096 16.941H9.55l.331-9.882H7.765l.33 9.882ZM15.904 16.941h-1.456l-.33-9.882h2.117l-.33 9.882Z"
      fill="#F5D171"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm0-1.412c5.848 0 10.588-4.74 10.588-10.588 0-5.848-4.74-10.588-10.588-10.588C6.152 1.412 1.412 6.152 1.412 12c0 5.848 4.74 10.588 10.588 10.588Z"
      fill="#F5D171"
    />
  </svg>
);

export default SvgComponent;
