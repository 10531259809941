import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";

const Background = styled(Img)`
  position: absolute !important;
  top: 0px;
  left: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  pointer-events: none;

  img {
    object-position: bottom !important;
  }
`;

function BackgroundHeader({
  marquee_background,
  marquee_background_red_room,
  pageContext,
}) {
  const image =
    pageContext.path === "/tour/red-room/"
      ? marquee_background_red_room
      : marquee_background;
  return (
    <Background
      className="background-image"
      fluid={image?.fluid}
      loading="eager"
      fadeIn
      alt={image?.alt}
    />
  );
}

export default BackgroundHeader;
