import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useViewportScroll } from "framer-motion";
import Link from "../john-gatsby-helpers/link";
import LinkExternal from "../john-gatsby-helpers/link-external";
import { GridWrapper } from "../layout-components";

/**
 * Data hooks
 */
import SvgLoader from "../svg-loader";
import useMeta from "../../hooks/use-meta";
import { NavLogo } from "./nav-logo";
import { gtagClickEvent } from "../../functions/util";

function Links({ data, menu, showMenu, setShowMenu, ...props }) {
  return (
    <NavLinksWrapper {...props} data-no-print>
      {data.map(({ href, text, icon, type, event }, i) => {
        if (type === "external") {
          return (
            <LinkWrap
              data-testid={`nav_link_${event}`}
              key={i}
              className="nav-link p-title"
              href={href}
              onClick={() => {
                gtagClickEvent(event, "clicked");
                menu && setShowMenu(!showMenu);
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}{" "}
              {icon && (
                <IconWrap>
                  <SvgLoader icon={icon} />
                </IconWrap>
              )}
            </LinkWrap>
          );
        }
        return (
          <Link
            data-testid={`nav_link_${event}`}
            key={i}
            className="nav-link p-title"
            to={href}
            onClick={() => {
              gtagClickEvent(event, "clicked");
              menu && setShowMenu(!showMenu);
            }}
          >
            {text}
          </Link>
        );
      })}
    </NavLinksWrapper>
  );
}

/**
 * Nav component
 */
export default function Nav() {
  const { scrollY } = useViewportScroll();
  const [showMenu, setShowMenu] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const [min, setMin] = React.useState(false);

  const meta = useMeta();
  const NavLeft = [
    {
      text: "Tour",
      href: "/tour",
      subNav: [
        {
          name: "overview",
          href: "/tour",
          event: "",
        },
        {
          name: "item",
          href: "/",
          event: "",
        },
        {
          name: "item",
          href: "/",
          event: "",
        },
        {
          name: "item",
          href: "/",
          event: "",
        },
      ],
    },
    {
      text: "Menus",
      href: "/menus",
      subNav: [
        {
          name: "overview",
          href: "/menus",
          event: "",
        },
        {
          name: "item",
          href: "/",
          event: "",
        },
        {
          name: "item",
          href: "/",
          event: "",
        },
        {
          name: "item",
          href: "/",
          event: "",
        },
      ],
    },
    {
      text: "About",
      href: "/about",
      event: "fromNav_linkTo_about",
    },
  ];
  const NavRight = [
    {
      text: "Contact",
      href: "/contact",
      event: "fromNav_linkTo_contact",
    },
    {
      text: "Inquire",
      href: "/inquire",
      event: "fromNav_linkTo_form_inquiry",
    },
    {
      text: meta.phone_number,
      href: `tel:+1${meta.phone_number}`,
      icon: "ArrowExternal",
      type: "external",
      event: "fromNav_linkTo_phone_inquiry",
    },
  ];

  function update() {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
      if (scrollY?.current < 50) {
        setMin(false);
      }
    } else if (scrollY?.current > 50 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
      setMin(true);
    }
  }

  useEffect(() => scrollY.onChange(() => update()), [scrollY, update]);

  useEffect(() => {
    setShowMenu(false);
  }, []);

  const wrapperVariants = {
    pre: {
      opacity: 0,
      y: -10,
      transition: {
        delay: 0.3,
      },
    },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <NavWrapper
      initial="pre"
      animate={hidden ? "hidden" : "visible"}
      variants={wrapperVariants}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.8 }}
      data-min={min}
      data-hidden={hidden}
      aria-label="Navigation Bar"
      className="nav"
    >
      {!showMenu && <Links data={NavLeft} className="left-nav" />}
      <NavLogo src="/" hidden={hidden} />
      {!showMenu && <Links data={NavRight} className="right-nav" />}
      <MenuButton
        onClick={() => setShowMenu(!showMenu)}
        data-cy="nav-menu-button"
        aria-label={showMenu ? "Close Menu" : "Open Menu"}
        data-no-print
      >
        <SvgLoader
          icon="IconSandwich"
          data-nav-button="open"
          data-active={`${!showMenu}`}
        />
        <SvgLoader
          icon="IconX"
          data-nav-button="close"
          data-active={`${showMenu}`}
        />
      </MenuButton>
      <Menu
        animate={showMenu ? "visible" : "hidden"}
        variants={{
          visible: {
            opacity: 1,
            height: "100vh",
          },
          hidden: {
            opacity: 0,
            height: "0vh",
          },
        }}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.3 }}
      >
        <Links
          data={[...NavLeft, ...NavRight]}
          className="nav-menu"
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menu
        />
      </Menu>
    </NavWrapper>
  );
}

const Menu = styled(motion.div)`
  position: fixed;
  inset: 0px;
  background: ${({ theme }) => theme.colors.black};
  z-index: -1;
  overflow-x: hidden;
  overflow-y: scroll;

  .nav-menu {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 40rem;
    padding: 10rem 0 8rem;

    a {
      font-family: "DomaineSansDisplay";
      font-feature-settings: "case" on;
      font-size: ${({ theme }) => theme.fonts.h1} !important;
      line-height: 105%;
      letter-spacing: 0;
      text-transform: none;
      text-decoration: none;
      pointer-events: visible;
      margin: 1rem 0;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;

const MenuButton = styled.button`
  width: 3rem;
  height: 3rem;
  grid-area: 1 / 14 / 2 / end-rail;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  appearance: none;
  pointer-events: visible;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  [data-nav-button] {
    position: absolute;
    transform: none;
    transition: opacity ${({ theme }) => theme.animation.duration[100].css},
      transform ${({ theme }) => theme.animation.duration[200].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};

    &[data-active="true"] {
      opacity: 1 !important;
    }
    &[data-active="false"] {
      opacity: 0 !important;
    }
  }

  [data-nav-button="open"] {
    &[data-active="false"] {
      transform: translateY(-50%) !important;
    }
  }

  [data-nav-button="close"] {
    &[data-active="false"] {
      transform: translateY(50%) !important;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;

const LinkWrap = styled(LinkExternal)`
  display: inline-flex;
  align-items: center;
`;

const IconWrap = styled.span`
  width: 18px;
  height: 4px;
  display: inline-flex;
  align-items: center;
  justify-items: center;

  svg {
    transform: scale(0.75);
  }
`;

const NavWrapper = styled(GridWrapper)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  padding: 1.5rem 0;

  @media ${({ theme }) => theme.device.laptop} {
    padding: 2.5rem 0;
  }

  .left-nav {
    display: none;

    @media ${({ theme }) => theme.device.laptop} {
      display: flex;
      grid-area: 1 / start-rail / 2 / 7;
    }
  }
  .right-nav {
    display: none;

    @media ${({ theme }) => theme.device.laptop} {
      display: flex;
      grid-area: 1 / 11 / 2 / end-rail;
    }
  }
  .logo-nav {
    display: grid;
    grid-area: 1 / start-rail / 2 / 3;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;

    @media ${({ theme }) => theme.device.laptop} {
      grid-area: 1 / 8 / 2 / 10;
    }
  }

  a {
    pointer-events: visible;
  }

  svg {
    fill: ${({ theme }) => theme.colors.black};
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    background: linear-gradient(180deg, #0f2707 0%, rgba(15, 39, 7, 0) 100%);
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transition: opacity ${({ theme }) => theme.animation.duration[200].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  }

  &[data-min="true"] {
    &:after {
      opacity: 1;
    }
  }

  &[data-hidden="true"] {
    .nav-menu a {
      pointer-events: none;
    }
  }

  [data-show-mobile="true"] {
    display: initial;

    @media ${({ theme }) => theme.device.laptop} {
      display: none;
    }
  }

  [data-show-mobile="false"] {
    display: none;

    @media ${({ theme }) => theme.device.laptop} {
      display: initial;
    }
  }
`;

const NavLinksWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform ${({ theme }) => theme.animation.duration[100].css},
    opacity ${({ theme }) => theme.animation.duration[100].css}, visibility 0ms;
  transition-timing-function: ${({ theme }) =>
    theme.animation.timingFunction.css};

  .nav-link {
    font-size: ${({ theme }) => theme.fonts.title} !important;

    &:after {
      content: "";
      width: 95%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0px;
      background: ${({ theme }) => theme.colors.golden};
      transition: transform ${({ theme }) => theme.animation.duration[100].css};
      transition-timning-function: ${({ theme }) =>
        theme.animation.timingFunction.css};
      transform-origin: right top;
      transform: scaleX(0);
    }

    &:hover {
      text-decoration: none;

      &:after {
        transform: scaleX(1) scaleY(2.5);
        transform-origin: left top;
      }
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: ${({ theme }) => theme.fonts.titleLarge} !important;
    }import { handler } from '../../lambda/hello';

  }
`;
