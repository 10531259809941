import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={201}
      height={54}
      viewBox="0 0 201 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.577 31.087c.061-.309-1.712-.309-1.895.802-.672 3.764-1.895 6.665-2.75 6.541-.673-.123-1.101-1.975-.429-5.677.856-4.443 2.568-6.788 3.424-6.418.489.124.305 1.605-.184 3.086-.305.802-.428.987.184.864.672-.124 1.772-.617 1.895-1.296.06-1.358-.428-2.9-1.468-3.27-1.65-.618-5.134 2.406-6.112 7.528-.856 4.628.733 7.034 2.078 6.787 1.223-.185 2.445-2.468 3.179-5.245-.061 3.27.611 5.8 1.589 5.554 1.284-.309 3.118-4.32 4.035-8.7.122-.618-.612 0-.612.06-1.161 5-2.262 7.344-2.934 7.22-.489-.123-1.223-2.53 0-7.836zM31.11 32.383c-1.283 4.196-1.65 4.38-1.71 4.01-.062-.431-.062-.987.366-7.774.917-14.934 1.345-18.698 1.528-19.87.061-.679-.305-.988-1.773.37-.427.432-.733.802-.794 1.851-.306 4.69-1.712 9.75-5.93 23.017-2.69 8.33-4.095 10.922-5.318 12.342-.611.74-.183 1.913.795.925 1.528-1.542 3.117-5.307 5.868-13.267 2.873-8.207 3.851-11.663 4.34-13.822.123-.74.245-.864.184.247-.245 2.591-.306 1.604-.856 8.453-.428 6.418-.611 10.059-.428 10.8.367 1.172 1.406 1.604 1.834.925.55-.864 1.1-2.222 3.117-8.207 2.812-8.207 4.218-13.33 4.463-14.131.122-.68.305-.494.183.617-.183 2.591-.306 2.715-.794 9.564-.856 12.157-.428 19.809 3.24 21.29 2.506 1.049 4.156-.803 4.767-2.037.367-.74 0-2.9-.305-2.283-.428 1.296-1.834 3.085-3.118 2.592-3.179-1.234-3.117-8.701-2.69-20.92.612-15.18 1.223-17.894 1.406-19.128.123-1.173-.428-1.358-1.589-.309-.489.432-1.1 1.604-1.223 2.653-.672 4.443-3.178 14.502-5.562 22.092z"
        fill="#F5D171"
      />
      <path
        d="M45.152 26.829c.06-.37-2.568-.247-2.69.864-.917 7.899.428 12.897 1.65 12.65 1.407-.185 3.057-4.258 3.913-8.7.122-.618-.612 0-.673.06-1.222 5.184-2.14 7.282-2.75 7.22-.978-.184-.978-4.38.55-12.094zm1.65-8.577c.122-.37-3.3-.864-3.362.617-.061 1.728.061 3.209.061 3.764.061.679.734.247.856.123.734-1.172 2.078-3.332 2.445-4.504z"
        fill="#F5D171"
      />
      <path
        d="M58.05 31.704c-1.345 5.43-2.14 7.281-2.812 7.22-1.1-.185-1.222-4.813-.733-12.897.611-11.293 1.467-17.772 1.895-19.561 0 0-.917-1.481-1.406.246-.917 3.27-1.773 9.812-2.384 18.883-1.834-.123-5.013 2.221-5.807 7.22-.795 4.875.978 7.405 2.323 6.973 1.039-.37 2.445-2.962 3.3-6.048.184 4.567 1.162 6.541 2.323 6.603 1.406.123 2.934-3.826 3.974-8.7.06-.618-.673 0-.673.06zm-8.435 6.417c-.734-.123-1.04-2.036-.49-5.553.673-4.258 2.446-6.418 3.424-6.233v.68c-.123 1.048-.123 1.974-.123 2.9-.489 3.332-1.833 8.392-2.812 8.206zM65.742 33.555c-1.712 0-4.34 0-5.807.062-.428.062-.428.432-.062.494 1.162.246 3.302.617 4.768.802 1.406.185 1.712.185 2.323-.247 1.345-.926 1.529-1.05-1.222-1.11zM79.447 24.546c2.75-5.06 4.89-14.008 5.012-19.253.245-7.652-4.157-6.788-8.68 1.173-3.179 5.491-6.052 15.241-7.03 24.744-1.04 8.948 1.1 17.895 5.685 18.08 2.873.062 5.196-3.085 6.113-4.75.367-.741 0-2.099-.245-1.482-.733 1.79-2.873 4.875-5.012 4.69-3.668-.37-4.707-8.269-3.851-17.648.856-8.578 3.606-17.71 6.113-22.894 2.628-5.368 5.623-6.603 5.44-.802-.122 3.64-1.895 13.144-4.401 18.08-.184.247.366.864.856.062z"
        fill="#F5D171"
      />
      <path
        d="M87.145 30.038c.306-2.345-.122-4.135-1.834-4.381-1.956-.309-4.829 2.468-5.379 7.343-.611 5.307 2.078 7.466 4.096 7.528 2.445.123 5.379-4.01 6.357-8.886.122-.617-.611 0-.611.062-1.284 5.43-3.73 7.466-5.318 7.405-1.406-.124-2.812-3.147-2.262-7.158.55-4.258 1.773-5.739 2.69-5.616.794.124 1.039 2.037.489 4.197-.428 1.912-1.529 3.455-2.323 4.319-.428.494-.367.987.183.555 1.65-1.172 3.545-3.147 3.912-5.368z"
        fill="#F5D171"
      />
      <path
        d="M89.003 39.294c.245.74 1.59.987 1.59.432v-.864c.916-6.48 2.872-10.058 3.667-10.429.306-.185.244.617.122 2.962-.489 6.85.55 9.195 1.773 8.948 1.1-.185 2.934-4.381 3.851-8.7.061-.618-.611-.063-.672 0-1.223 5.244-2.14 7.342-2.812 7.219-.917-.123-1.1-4.566.428-12.218.183-.679.122-1.481-.978-1.234-.978.247-3.424 2.221-5.013 8.824l.611-6.85c.123-.617.184-1.296.306-1.913.061-.308-2.262-.123-2.323.988 0 .185-.061.864-.061 1.851 0 2.16-.672 10.429-.489 10.984z"
        fill="#F5D171"
      />
      <path
        d="M102.343 22.448c3.056-.309 5.685-2.962 6.235-3.888.611-.987-.734.185-4.035.617-1.039.124-1.956.247-2.75.247.183-3.455.427-5.615.733-8.083 0-.186-.733-1.173-1.222.987-.428 1.79-.856 4.381-1.223 7.158-2.078 0-3.179-.247-3.484-.494-.673-.37.06-1.48-.367-1.42-.428.124-1.223 1.42-.672 2.84.427 1.049 2.139 1.85 4.217 2.036-.183 2.283-.428 4.69-.489 6.788-.489 9.75.978 13.205 3.24 13.76.611.124.673-.432.428-.555-1.284-.309-2.139-3.888-1.65-14.131.122-2.221.244-4.134.305-5.739.245 0 .489-.062.734-.123z"
        fill="#F5D171"
      />
      <path
        d="M106.985 25.78c.061-.37-2.323-.185-2.445.926-.062 1.48-.062.617-.123 2.715-.305 4.998-.122 10.613 1.895 10.922 1.223.247 2.506-2.654 3.362-6.233 0 3.333.306 6.48 1.467 6.233 1.406-.185 3.057-4.258 3.974-8.7.122-.556-.612 0-.673.06-1.222 5.184-2.139 7.344-2.995 7.22-.917-.184-.55-6.972.734-13.575.061-.308-1.712-.247-1.834.864-.184 1.48-.123.679-.367 2.715v.309c-.917 5.43-2.017 9.873-2.995 9.688-1.101-.185-1.101-6.11 0-13.144z"
        fill="#F5D171"
      />
      <path
        d="M119.159 25.471c-1.101.494-2.201 2.716-3.118 3.148-.611.308-.611-.432.306-3.456.367-1.42.489-1.851-.184-1.79-.855.124-1.833.803-1.956 1.543-.489 2.283-.061 3.888.612 4.381-.062.864-.245 1.913-.612 3.641-.122.247.55.37.673-.185.305-1.11.428-2.283.55-3.27.733-.062 1.345-.68 1.956-.988.306-.185.244.617.061 2.9-.428 6.85 1.039 9.195 2.323 8.948 1.1-.185 2.934-4.32 3.79-8.7.122-.556-.611 0-.672.06-1.223 5.246-2.14 7.282-2.751 7.22-.978-.184-1.528-4.566 0-12.28.122-.616 0-1.727-.978-1.172z"
        fill="#F5D171"
      />
      <path
        d="M128.186 34.666c-.245 2.592-.551 5.06-.917 7.22-2.934-.062-5.991 1.48-7.702 4.01-3.057 4.32-1.773 8.763 1.528 8.023 3.178-.74 5.44-4.567 6.846-9.935 1.589.246 3.301.925 4.89 2.036.306.185.367-1.234-.611-2.098a6.582 6.582 0 00-3.79-1.913 83.65 83.65 0 001.223-7.652c.489-5.121 1.039-8.207 1.161-9.009 0-.308-1.711-.247-1.895.864l-.306 2.715c-.061.123-.061.309-.061.432-.917 5.369-1.956 9.75-2.995 9.565-1.039-.185-1.039-6.11.061-13.144.061-.37-2.323-.185-2.384.926-.122 1.48-.122.617-.244 2.715-.306 4.998-.061 10.613 1.956 10.922 1.161.185 2.384-2.407 3.24-5.862v.185zm-5.991 17.772c-2.995 1.11-4.034-2.839-1.956-5.986 1.284-1.851 3.729-2.838 6.663-2.653-.978 4.69-2.506 7.836-4.707 8.639zM149.29 24.546c2.812-5.06 4.951-14.008 5.074-19.253.183-7.652-4.218-6.788-8.68 1.173-3.179 5.491-6.052 15.241-7.091 24.744-1.04 8.948.978 17.895 5.562 18.08 3.851.062 7.825-5.985 9.964-10.428.489 1.481 1.284 2.468 2.445 2.53 1.773.062 4.585-3.826 5.746-9.75.061-.493-.611-.123-.611.062-1.039 4.69-3.301 8.577-4.829 8.392-1.039-.185-1.406-1.79-1.467-4.381v-.185c3.484-7.96 6.357-20.302 6.724-26.35.244-4.998-2.873-4.566-5.013.68-1.711 4.504-3.117 13.143-3.667 19.314-.245 2.9-.184 5.924.305 8.269-1.956 4.258-5.623 10.49-8.741 10.305-3.668-.37-4.523-8.269-3.729-17.648.856-8.578 3.668-17.71 6.174-22.894 2.629-5.368 5.624-6.603 5.379-.802-.122 3.64-1.833 13.144-4.34 18.08-.183.247.367.864.795.062zm6.419 3.826c.489-6.233 1.711-15.304 3.362-19.438 1.039-2.654 2.261-2.9 2.017.247-.245 4.134-2.568 15.303-5.685 24.189.061-1.481.183-3.147.306-4.998z"
        fill="#F5D171"
      />
      <path
        d="M168.456 31.087c.061-.309-1.711-.309-1.895.802-.672 3.764-1.895 6.665-2.751 6.541-.672-.123-1.1-1.975-.428-5.677.856-4.443 2.568-6.788 3.424-6.418.489.124.305 1.605-.184 3.086-.305.802-.428.987.184.864.672-.124 1.772-.617 1.895-1.296.061-1.358-.428-2.9-1.467-3.27-1.651-.618-5.135 2.406-6.113 7.528-.856 4.628.733 7.034 2.078 6.787 1.223-.185 2.445-2.468 3.179-5.245-.061 3.27.611 5.8 1.589 5.554 1.284-.309 3.118-4.32 4.035-8.7.122-.618-.612 0-.612.06-1.161 5-2.261 7.344-2.934 7.22-.489-.123-1.222-2.53 0-7.836z"
        fill="#F5D171"
      />
      <path
        d="M178.003 38.06c-.795 1.42-.428 3.455 1.528 3.764 2.14.309 4.279-1.172 4.891-3.58.366-1.295.122-2.714-.367-4.01a15.121 15.121 0 013.178-1.11c.367 0 .551-.495.184-.433-1.223.185-2.445.556-3.607.988-.611-1.42-1.528-2.839-2.323-4.135-1.039-1.543-.978-3.517 0-6.047.184-.432.184-1.481-.672-1.481-.978 0-1.956.987-2.14 2.16-.183.987.123 2.098.489 3.147-.611 1.48-1.589 3.209-3.178 5.121-.55-.617-1.1-1.357-1.712-2.345-.978-1.542-.978-3.517 0-6.047.184-.432.245-1.48-.672-1.48s-1.956.986-2.14 2.159c-.183 1.234.245 2.653.795 3.826-.306 1.172-.672 2.53-1.1 4.196 0 .247.489.37.672-.185.245-1.05.611-2.222.856-3.147l.55.925c.611.926 1.345 2.037 1.712 3.333l-.978 1.049c-2.262 2.406-3.424 3.764-2.69 5.121.978 2.037 6.113 1.173 6.113-3.887 0-1.173-.428-2.037-1.039-2.962 1.467-1.79 2.506-3.209 3.301-4.628.305.617.55 1.11.733 1.419.917 1.296 1.712 2.9 2.017 4.566-2.017 1.05-3.606 2.345-4.401 3.703zm4.096.74c-.551 1.296-1.223 2.222-2.262 2.284-1.589.061-1.589-1.667-.917-2.84.611-1.048 2.017-2.159 3.607-3.146a8.576 8.576 0 01-.428 3.702zm-6.786-2.221c-.183 3.147-2.689 4.072-3.3 2.838-.367-.74.427-2.16 2.689-4.566l.428-.494c.122.68.245 1.42.183 2.222z"
        fill="#F5D171"
      />
      <path
        d="M189.555 26.829c.061-.37-2.568-.247-2.69.864-.917 7.899.428 12.897 1.651 12.65 1.406-.185 3.056-4.258 3.912-8.7.122-.618-.611 0-.673.06-1.222 5.184-2.139 7.282-2.75 7.22-.978-.184-.978-4.38.55-12.094zm1.65-8.577c.123-.37-3.301-.864-3.362.617-.061 1.728.061 3.209.061 3.764.062.679.734.247.856.123.734-1.172 2.079-3.332 2.445-4.504z"
        fill="#F5D171"
      />
      <path
        d="M194.507 40.59c2.445 0 5.44-4.073 6.479-8.948.123-.555-.611 0-.672.062-1.345 5.43-3.546 7.405-5.196 7.405-1.223 0-2.323-2.098-1.773-6.603.55-4.32 2.079-6.603 3.118-6.17.794.308.672 2.036-.245 4.874-.489 1.42-.55 1.728.123 1.605.855-.062 1.589-.556 1.711-1.296.611-3.27.061-5.369-1.1-5.8-2.14-.803-5.135 2.776-5.807 7.774-.612 4.628 1.283 7.097 3.362 7.097z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
