import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.588 12c0 5.848-4.74 10.588-10.588 10.588-5.848 0-10.588-4.74-10.588-10.588C1.412 6.152 6.152 1.412 12 1.412c5.848 0 10.588 4.74 10.588 10.588ZM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-7.071.714C15.115 12.714 12 14 10.429 17H9V7h1.429c1.5 3 4.686 4.286 6.5 4.286v1.428Z"
      fill="#F5D171"
    />
  </svg>
);

export default SvgComponent;
