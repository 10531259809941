import { motion } from "framer-motion";
import styled from "styled-components";

export const PageWrapper = styled(motion.main)`
  margin: 0 auto;
  max-width: 100vw;
`;

export const GridWrapper = styled(motion.section)`
  --rail-outer: 0px;
  --rail-inner: calc(${({ theme }) => theme.layout.outerRailBase} - 2rem);
  --row-gap: 0.5rem;
  --column-gap: 0.5rem;
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns:
    var(--rail-outer)
    [start]
    var(--rail-inner)
    [start-rail]
    repeat(12, 1fr [col-start])
    [end-rail]
    var(--rail-inner)
    [end]
    var(--rail-outer);
  grid-template-rows: auto;
  row-gap: var(--row-gap);
  column-gap: var(--column-gap);
  position: relative;
  z-index: 1;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    --rail-outer: 1rem;
    --rail-inner: calc(${({ theme }) => theme.layout.outerRailTablet} - 2rem);
  }

  @media only screen and ${({ theme }) => theme.device.desktop} {
    --rail-outer: 3rem;
    --rail-inner: calc(${({ theme }) => theme.layout.outerRailDesktop} - 3rem);
  }
`;

export const GridSectionLeft = styled.div`
  --row-gap: 1.5rem;
  display: grid;
  grid-column: start-rail / end-rail;
  order: ${({ mobileOrder }) => mobileOrder || "unset"};
  row-gap: var(--row-gap);

  @media only screen and ${({ theme }) => theme.device.tablet} {
    order: ${({ tabletOrder }) => tabletOrder || "unset"};
    grid-column: start-rail / col-start 6;
  }
`;
export const GridSectionRight = styled.div`
  --row-gap: 1.5rem;
  display: grid;
  grid-column: start-rail / end-rail;
  order: ${({ mobileOrder }) => mobileOrder || "unset"};
  row-gap: var(--row-gap);

  @media only screen and ${({ theme }) => theme.device.tablet} {
    order: ${({ tabletOrder }) => tabletOrder || "unset"};
    grid-column: col-start 6 / end-rail;
  }
`;

export const Wrapper = styled(motion.section)`
  --rail: ${({ theme }) => theme.layout.outerRailBase};
  padding: 0 var(--rail);
  width: 100%;
  position: relative;
  z-index: 1;

  @media ${({ theme }) => theme.device.tablet} {
    --rail: ${({ theme }) => theme.layout.outerRailTablet};
  }

  @media ${({ theme }) => theme.device.desktop} {
    --rail: ${({ theme }) => theme.layout.outerRailDesktop};
  }

  &.pV {
    padding-top: ${({ theme }) => theme.layout.space[500]};
    padding-bottom: ${({ theme }) => theme.layout.space[500]};
  }

  &.pT {
    padding-top: ${({ theme }) => theme.layout.space[500]};
  }

  &.pB {
    padding-bottom: ${({ theme }) => theme.layout.space[500]};
  }
`;
