import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.5c-4.5 0-9-.25-9-.25v2.5s4.09-.25 9-.25 9 .25 9 .25v-2.5s-4.5.25-9 .25Zm-9 6.25s4.5.25 9 .25 9-.25 9-.25v2.5S16.91 13 12 13s-9 .25-9 .25v-2.5Zm0 6.5s4.5.25 9 .25 9-.25 9-.25v2.5s-4.09-.25-9-.25-9 .25-9 .25v-2.5Z"
      fill="#F5D171"
    />
  </svg>
);

export default SvgComponent;
