import { createGlobalStyle } from "styled-components";

/**
 * makeHover
 * For making accessible hover effects. Create the hover effect
 * and shows the hover effect for devices that dont have hover
 *
 * @param {TemplateStringsArray} content css propterties
 * @returns {String} returns css string
 */
export function makeHover(content) {
  return `
    &:hover {
      ${content}
    }

    @media (hover: none) {
      ${content}
    }

    &:focus-visible {
      ${content}
    }
  `;
}

const BaseStyles = createGlobalStyle`
  @font-face {
    font-family: 'DomaineSansDisplay';
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/domaine-sans-display-web-regular.eot') format('eot'),
      url('/fonts/domaine-sans-display-web-regular.woff2') format('woff2'),
      url('/fonts/domaine-sans-display-web-regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'DomaineSansTextBold';
    font-display: auto;
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/domaine-sans-text-web-bold.eot') format('eot'),
      url('/fonts/domaine-sans-text-web-bold.woff2') format('woff2'),
      url('/fonts/domaine-sans-text-web-bold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'DomaineSansTextRegular';
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/domaine-sans-text-web-regular.eot') format('eot'),
      url('/fonts/domaine-sans-text-web-regular.woff2') format('woff2'),
      url('/fonts/domaine-sans-text-web-regular.woff') format('woff');
    font-display: swap;
  }

  :root {
    --outer-rail: ${({ theme }) => theme.layout.outerRailBase};

    @media only screen and ${({ theme }) => theme.device.tablet} {
      --outer-rail: ${({ theme }) => theme.layout.outerRailTablet};
    }

    @media only screen and ${({ theme }) => theme.device.desktop} {
      --outer-rail: ${({ theme }) => theme.layout.outerRailDesktop};
    }
  }

  html {
    background-color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fonts.paragraph};
  }

  body {
    font-family: 'DomaineSansTextRegular', sans-serif;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.golden};
    fill: ${({ theme }) => theme.colors.golden};
  }

  * {
    box-sizing: border-box;
    transition-timing-function: ${(props) =>
      props.theme.animation.timingFunction.css};
  }

  main#main {
    min-height: 100vh;
    z-index: 1;
    position: relative;
  }

  a {
    text-decoration: none;
    position: relative;
    color: ${({ theme }) => theme.colors.golden};
    transition: opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css};
    opacity: 1;

    ${makeHover`
      text-decoration: underline;
      opacity: .9;
    `};
  }

  *:focus-visible {
    position: relative;
  }

  pre, code {
    font-family: monospace;
    font-weight: 400;
    margin: 1rem 0;
  }

  hr {
    border: solid 1.5px ${({ theme }) => theme.colors.golden};
    background: ${({ theme }) => theme.colors.golden};
    margin: 3rem 0;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  .p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    font-family: 'DomaineSansDisplay';
    font-feature-settings: 'case' on;
    margin-left: -0.05em;
  }

  h1,
  .h1 {
    font-size: ${({ theme }) => theme.fonts.h1};
    line-height: 105%;
  }

  h2,
  .h2 {
    font-size: ${({ theme }) => theme.fonts.h2};
    line-height: 105%;
  }

  h3,
  .h3 {
    font-size: ${({ theme }) => theme.fonts.h3};
    line-height: 100%;
  }

  h4,
  .h4 {
    font-size: ${({ theme }) => theme.fonts.h4};
    line-height: 130%;
  }

  h5,
  .h5 {
    font-size: ${({ theme }) => theme.fonts.h5};
    line-height: 130%;
    letter-spacing: 0.01em;
  }

  h6,
  .h6 {
    font-family: 'DomaineSansTextBold';
    font-size: ${({ theme }) => theme.fonts.h6};
    line-height: 130%;
    letter-spacing: 0.4em;
    text-transform: uppercase;
  }

  p.title-large,
  .p-title-large {
    font-family: 'DomaineSansTextBold';
    font-size: ${({ theme }) => theme.fonts.titleLarge};
    line-height: 130%;
    letter-spacing: 0.4em;
    text-transform: uppercase;
  }

  p.title,
  .p-title {
    font-family: 'DomaineSansTextBold';
    font-size: ${({ theme }) => theme.fonts.title};
    line-height: 130%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  p.title-small,
  .p-title-small {
    font-family: 'DomaineSansTextBold';
    font-size: ${({ theme }) => theme.fonts.titleSmall};
    line-height: 130%;
    letter-spacing: 0.4em;
    text-transform: uppercase;
  }

  p.large,
  .p-large {
    font-size: ${({ theme }) => theme.fonts.paragraphLarge};
    line-height: 130%;
    letter-spacing: 0.01em;
  }

  p,
  .p {
    font-family: 'DomaineSansTextRegular';
    font-style: normal;
    font-size: ${({ theme }) => theme.fonts.paragraph};
    line-height: 130%;
    font-feature-settings: 'ss05' on, 'ss03' on, 'ss07' on, 'calt' off,
      'liga' off;

    &.strong {
      font-family: 'DomaineSansTextBold';
    }

    &.paragraph-block {
      line-height: 280%;
      white-space: pre-line;
    }
  }

  p.small,
  .p-small {
    font-size: ${({ theme }) => theme.fonts.paragraphSmall};
    line-height: 130%;
    letter-spacing: 0.02em;
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
  }

  .ReactModal__Overlay {
    opacity: 0;
    will-change: opacity;
    transition: opacity ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};

  }

  .ReactModal__Content {
    transition: transform ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};
    transform: translateY(50%);
    will-change: transform;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;

    .ReactModal__Content {
      transform: none;
    }
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;

    .ReactModal__Content {
      transform: translateY(50%);
    }
  }
`;

export default BaseStyles;
