import { useStaticQuery, graphql } from "gatsby";

export const useBackgroundImage = () => {
  const data = useStaticQuery(graphql`
    query backgroundImageQuery {
      prismicHomepage {
        uid
        id
        data {
          marquee_background {
            fluid(
              placeholderImgixParams: { blur: 600, px: 1 }
              maxWidth: 2500
              imgixParams: { fm: "webp" }
            ) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          marquee_background_red_room {
            fluid(
              placeholderImgixParams: { blur: 600, px: 1 }
              maxWidth: 2500
              imgixParams: { fm: "webp" }
            ) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
        }
      }
    }
  `);

  return data.prismicHomepage.data;
};

export default useBackgroundImage;
