import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 9.5c-4.056 0-6-3.46-6-6h-2c0 2.54-1.944 6-6 6V12c2.76 0 4.521-1.506 5.5-3.5.155-.315.3-.654.436-1h.137l-.173 13h2.2l-.173-13h.137c.136.346.281.685.436 1 .979 1.994 2.74 3.5 5.5 3.5V9.5z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
