exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-index-js": () => import("./../../../src/pages/inquire/index.js" /* webpackChunkName: "component---src-pages-inquire-index-js" */),
  "component---src-pages-menus-index-js": () => import("./../../../src/pages/menus/index.js" /* webpackChunkName: "component---src-pages-menus-index-js" */),
  "component---src-pages-prismic-page-uid-js": () => import("./../../../src/pages/{PrismicPage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-page-uid-js" */),
  "component---src-pages-prismic-thank-you-uid-js": () => import("./../../../src/pages/{PrismicThankYou.uid}.js" /* webpackChunkName: "component---src-pages-prismic-thank-you-uid-js" */),
  "component---src-pages-style-index-js": () => import("./../../../src/pages/style/index.js" /* webpackChunkName: "component---src-pages-style-index-js" */),
  "component---src-pages-tour-index-js": () => import("./../../../src/pages/tour/index.js" /* webpackChunkName: "component---src-pages-tour-index-js" */),
  "component---src-pages-tour-prismic-tour-page-uid-js": () => import("./../../../src/pages/tour/{PrismicTourPage.uid}.js" /* webpackChunkName: "component---src-pages-tour-prismic-tour-page-uid-js" */),
  "component---src-templates-menu-page-js": () => import("./../../../src/templates/menu-page.js" /* webpackChunkName: "component---src-templates-menu-page-js" */)
}

