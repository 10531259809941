import * as React from "react";

function ArrowExternal(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.218 15.182c-2.868-2.868-1.796-6.69 0-8.485l-1.415-1.415c-1.795 1.796-5.617 2.869-8.485 0L6.55 7.05c1.952 1.952 4.262 2.132 6.364 1.414.332-.113.675-.25 1.016-.399l.097.098-9.315 9.07 1.556 1.555 9.07-9.315.097.098c-.15.34-.286.683-.4 1.015-.717 2.102-.537 4.412 1.415 6.364l1.768-1.768z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default ArrowExternal;
