import React from "react";
import styled, { createGlobalStyle } from "styled-components";

/**
 *
 */
import LinkExternal from "../john-gatsby-helpers/link-external";
import { Paragraph } from "../type";
import SvgLoader from "../svg-loader";
import Link from "../john-gatsby-helpers/link";

/**
 * Footer component
 *
 * @param {Object} props
 * @param {Object} props.pageContext
 */
export default function Footer({ pageContext }) {
  const date = new Date();

  return (
    <FooterContainer id="footer" data-page={pageContext.uid}>
      <FooterStyles />
      <FooterRow className="links">
        <FooterLinksWrapper>
          <SvgLoader logo="MidCenturyClassic" />
          <LegalWrapper>
            <Paragraph className="title-small">
              © {date.getFullYear()} Choura Venue Services
            </Paragraph>
            <Link to="/privacy-policy" data-no-print>
              <Paragraph className="title-small">Privacy Policy</Paragraph>
            </Link>
            <LinkExternal href="https://john.design" blank data-no-print>
              <Paragraph className="title-small">Site by John.Design</Paragraph>
            </LinkExternal>
          </LegalWrapper>
        </FooterLinksWrapper>
        <SvgLoader logo="FullLogo" data-show-mobile="true" />
        <SvgLoader logo="Monogram" data-show-mobile="false" />
      </FooterRow>
    </FooterContainer>
  );
}

const FooterStyles = createGlobalStyle`
  body {

    &.page-thank-you #footer,
    &.page-inquire #footer {
      display: none;
      opacity: 0;
      transform: translate3d(0, -1rem, 0);
      transition:
        transform ${({ theme }) => theme.animation.duration[100].css},
        opacity ${({ theme }) => theme.animation.duration[100].css},
        display 0ms ${({ theme }) => theme.animation.timingFunction.css}
          ${({ theme }) => theme.animation.duration[100].css};
    }
  }
`;

const FooterContainer = styled.footer`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.layout.space[400]} 0;
  transition: transform ${({ theme }) => theme.animation.duration[100].css},
    opacity ${({ theme }) => theme.animation.duration[100].css}, display 0ms;
  transition-timing-function: ${({ theme }) =>
    theme.animation.timingFunction.css};
  page-break-before: always;
  page-break-inside: avoid;

  [data-show-mobile="true"] {
    display: initial;

    @media ${({ theme }) => theme.device.laptopLg} {
      display: none;
    }
  }

  [data-show-mobile="false"] {
    display: none;

    @media ${({ theme }) => theme.device.laptopLg} {
      display: initial;
    }
  }
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => theme.layout.space[200]}
    ${({ theme }) => theme.layout.outerRailBase}
    ${({ theme }) => theme.layout.space[200]};
  width: calc(100vw - (${({ theme }) => theme.layout.outerRailBase} * 2));
  /* row-gap: ${({ theme }) => theme.layout.space[300]}; */

  .icon-wrap {
    margin-bottom: ${({ theme }) => theme.layout.space[300]};
  }

  @media ${({ theme }) => theme.device.laptopLg} {
    flex-direction: row;
    margin: ${({ theme }) => theme.layout.space[300]}
      ${({ theme }) => theme.layout.outerRailTablet};
    width: calc(100vw - (${({ theme }) => theme.layout.outerRailTablet} * 2));

    .icon-wrap {
      margin-bottom: 0;
    }
  }
`;

const FooterLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* row-gap: ${({ theme }) => theme.layout.space[200]}; */

  .icon-wrap {
    margin-bottom: ${({ theme }) => theme.layout.space[200]};
  }

  @media ${({ theme }) => theme.device.laptopLg} {
    /* row-gap: 0; */
    /* column-gap: ${({ theme }) => theme.layout.space[400]}; */
    flex-direction: row;

    .icon-wrap {
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.layout.space[400]};
    }
  }
`;

const LegalWrapper = styled.div`
  display: inline-flex;
  margin: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* row-gap: ${({ theme }) => theme.layout.space[100]}; */

  @media ${({ theme }) => theme.device.laptopLg} {
    flex-direction: row;
    /* column-gap: calc(${({ theme }) => theme.layout.space[100]} * 2); */
  }

  p {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.layout.space[100]};
    opacity: 0.75;

    @media ${({ theme }) => theme.device.laptopLg} {
      margin-bottom: 0;
      margin-right: calc(${({ theme }) => theme.layout.space[100]} * 2);
    }
  }
`;
