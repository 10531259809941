import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as icons from "./icons/index";
import * as logos from "./brand/index";

const Container = styled.span`
  display: inline-flex;

  svg path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`;

function SvgLoader({ icon, logo, color = "golden", className, ...rest }) {
  const Icon = (icon && icons[icon]) || icons.ArrowRight;
  const Logo = logo && logos[logo];

  return (
    <Container className={`icon-wrap ${className}`} color={color} {...rest}>
      {icon && !logo && <Icon />}
      {logo && !icon && <Logo />}
    </Container>
  );
}

SvgLoader.propTypes = {
  color: PropTypes.oneOf([
    "paper",
    "black",
    "evergreen",
    "golden",
    "red",
    "white",
  ]),
  icon: PropTypes.oneOf(Object.keys(icons)),
  logo: PropTypes.oneOf(Object.keys(logos)),
};

export default SvgLoader;
