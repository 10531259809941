import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10c4.056 0 6 3.46 6 6h2c0-2.54 1.944-6 6-6V7c-2.76 0-5.12 2.17-6.098 4.163-.53 1.079-.748 1.868-.838 2.337h-.127c-.09-.469-.31-1.258-.839-2.337C10.12 9.17 7.76 7 5 7v3z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
