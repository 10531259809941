import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={39}
      height={43}
      viewBox="0 0 39 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 .123V0h12.692v.123C9.706 1.35 9.146 3.809 9.146 7.863v33.54h18.337c6.288 0 7.82-3.101 10.481-8.49.288-.584.59-1.194.912-1.83H39L38.448 43H0v-.123c2.986-1.228 3.546-3.686 3.546-7.74V7.863c0-4.054-.56-6.512-3.546-7.74z"
        fill="#F5D171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.129 24.396c2.605 0 4.857-.932 4.857-3.534 0-2.583-2.27-3.515-4.857-3.515h-2.006v12.557h1.649v-5.508h.357zm-.357-.613v-5.898h.055c2.021 0 3.386.753 3.386 2.977 0 2.225-1.365 2.921-3.386 2.921h-.055z"
        fill="#F5D171"
      />
      <path
        d="M23.816 32.284c0 3.923 2.64 6.131 4.964 6.131 2.548 0 3.826-1.422 4.194-3.98h-.681c-.24 2.19-1.484 3.333-3.227 3.333-1.744 0-3.29-1.508-3.29-5.518s1.343-5.518 3.29-5.518 2.858 1.464 2.978 4.346h1.202c-.272-3.304-1.687-4.993-4.466-4.993-2.78 0-4.965 2.277-4.965 6.2z"
        fill="#F5D171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.26 17.153c2.709 0 4.768-1.036 4.768-3.363 0-2.182-1.948-3.044-4.118-3.099v-.166c1.836-.09 3.032-1.153 3.032-2.953 0-2.273-2.114-3.146-4.655-3.146h-1.98v12.727h2.954zm-1.228-6.879V5.042c2.133 0 3.204.457 3.204 2.53 0 2.363-1.085 2.702-2.829 2.702h-.375zm.431.666c2.319 0 3.858.614 3.858 2.886 0 2.418-1.39 2.727-3.487 2.727h-.802V10.94h.431z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
