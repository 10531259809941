import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={88}
      height={88}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60.054 36.602c-5.807-3.927-7.97-11.709-7.97-19.602H45.44c0 9.538 5.345 18.425 12.296 22.877 1.677 1.075 4.657 1.744 7.096 2.136v.42L6 41.512v5.883l58.831-.92v.38c-2.39.372-5.315 1.035-7.096 2.176C50.784 53.485 45.44 62.37 45.44 71.909h6.645c0-7.893 2.163-15.675 7.97-19.601 5.856-3.96 15.27-4.912 21.446-4.912v-5.883c-6.176 0-15.59-.952-21.446-4.911z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
