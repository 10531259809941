import { useStaticQuery, graphql } from "gatsby";

const useMeta = () => {
  const data = useStaticQuery(
    graphql`
      query metaQuery {
        prismicSiteMeta {
          id
          data {
            contact_email
            inquiry_email
            phone_number
            site_description
            site_url
            og_image {
              url
            }
          }
        }
      }
    `
  );

  return data.prismicSiteMeta.data;
};

export default useMeta;
