import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 13c-4.056 0-6-3.46-6-6h-2c0 2.54-1.944 6-6 6v3c2.76 0 5.12-2.17 6.098-4.163.53-1.079.748-1.869.838-2.337h.127c.09.468.31 1.258.839 2.337C13.88 13.83 16.24 16 19 16v-3z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
