import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={88}
      height={88}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M74 17v8c-27 1.5-33.5 29.398-33.5 46.949h-4c0-8.145-7.5-19.45-22.5-19.45V46c8.851 0 16.5 3.5 20.647 10.439C36.599 59.705 37.704 64.49 38 66h.5c0-16 4.5-44.5 35.5-49z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
