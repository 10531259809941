import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 18.5c0-4.056 3.46-6 6-6v-2c-2.54 0-6-1.944-6-6h-3c0 2.76 2.17 5.12 4.163 6.098 1.079.53 1.868.748 2.337.838v.127c-.469.09-1.258.31-2.337.839C9.67 13.38 7.5 15.74 7.5 18.5h3z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
