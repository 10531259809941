import * as React from "react";

function StarIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3c2.143 5.713 3.286 6.887 9 9-5.714 2.113-6.929 3.287-9 9-2.143-5.713-3.286-6.887-9-9 5.714-2.113 6.857-3.287 9-9z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default StarIcon;
