// @ts-nocheck
import React from "react";

/**
 * Headline component
 *
 * @component
 * @param {Object} props
 * @param {String} props.tag
 * @param {String} props.size
 * @param {Number} props.lineLength
 * @param {Object} props.style
 * @param {String} props.className
 * @param {any} props.children react children
 * @returns {React.ReactElement}
 */
export function Headline({
  tag = "p",
  size = "h1",
  lineLength = 1,
  style,
  children,
  className,
  ...rest
}) {
  const Tag = tag;

  return (
    <Tag
      className={`${className} ${size}`}
      style={{ maxWidth: lineLength && `${10 * lineLength}em`, ...style }}
      {...rest}
    >
      {children}
    </Tag>
  );
}

/**
 * Paragraph component
 *
 * @component
 * @param {Object} props
 * @param {String} props.tag
 * @param {String} props.size
 * @param {any} props.children react children
 */
export function Paragraph({
  tag = "p",
  lineLength,
  style,
  children,
  size,
  ...rest
}) {
  const Tag = tag;

  return (
    <Tag
      className={size}
      style={{ maxWidth: lineLength && `${10 * lineLength}em`, ...style }}
      {...rest}
    >
      {children}
    </Tag>
  );
}
