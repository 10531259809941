import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={164}
      height={50}
      viewBox="0 0 164 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.467 10.978c0 6.404 4.947 10.978 10.653 10.978s10.653-4.574 10.653-10.978S86.826 0 81.12 0 70.467 4.574 70.467 10.978zm3.126 0c0-6.892 2.701-10.673 7.527-10.673 4.825 0 7.527 3.781 7.527 10.673 0 6.892-2.702 10.674-7.527 10.674-4.826 0-7.527-3.782-7.527-10.674z"
        fill="#F5D171"
      />
      <path
        d="M106.757 21.652H96.105V.305h2.823v21.011h7.829v.336zM139.749.305v12.96c0 5.215-2.577 7.868-5.553 7.868-3.678 0-5.158-2.062-5.158-7.003V.305h-2.793V14.13c0 5.124 2.793 7.826 7.253 7.826 3.998 0 6.585-2.988 6.585-8.569V.305h-.334zM160.012.875l1.19 20.798h2.762L162.774.326h-2.883l-5.627 17.467L148.056.326h-3.096l-1.19 21.347h.334l1.19-20.798 7.83 21.103h.121L160.012.875z"
        fill="#F5D171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.093 11.802h-2.519v9.85h-2.822V.305h5.628c4.432 0 8.195 1.555 8.195 5.672 0 4.056-3.05 5.502-5.812 5.563v.279c2.793.213 4.432 1.202 5.22 3.46l1.184 3.384c.364 1.098.85 2.196 1.64 2.989H64.68c-.607-.854-.971-1.86-1.275-2.776l-1.153-3.293c-.88-2.623-2.094-3.781-5.16-3.781zM56.864.64h-2.29v10.826H57.7c3.217 0 4.87-1.311 4.87-5.428 0-3.781-2.276-5.398-5.706-5.398z"
        fill="#F5D171"
      />
      <path
        d="M39.73 21.652h2.792V.64h5.72V.305H34.01V.64h5.72v21.012zM31.871 21.652H19.84V.305h11.638V.64H22.66v9.85h6.737v.336h-6.736v10.49h9.21v.336zM109.91 21.652h12.033v-.336h-9.21v-10.49h6.737v-.336h-6.737V.64h8.815V.305H109.91v21.347z"
        fill="#F5D171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.956 6.282c0 4.422-3.854 6.008-8.316 6.008H4.23v9.362H1.406V.305H6.64c4.432 0 8.316 1.586 8.316 5.977zM4.23.64v11.314h1.895c3.46 0 5.797-1.89 5.797-5.672C11.921 2.501 9.584.64 6.124.64H4.23z"
        fill="#F5D171"
      />
      <path
        d="M108.612 49.891c4.165.272 7.789-2.01 7.789-6.032 0-4.348-4.115-5.585-6.93-5.707v-.163c2.412-.091 5.771-1.685 5.771-4.921 0-3.448-2.627-5.133-6.254-5.133-2.615 0-4.324 1.304-5.37 3.315l.883.38c.812-1.793 2.164-3.268 4.219-3.268 2.681 0 4.166 2.073 4.166 5.062 0 3.699-3.824 4.923-6.593 4.293v.707c3.03-.57 7.177 1.094 7.284 5.217.08 3.098-1.448 5.854-4.826 5.854-3.377 0-4.953-2.81-5.223-5.473l-1.082.326c.379 2.826 2.288 5.29 6.166 5.543z"
        fill="#F5D171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.332 36.413c3.399 0 6.458 2.12 6.458 6.522s-3.259 6.956-7.087 6.956c-4.342 0-7.679-4.562-7.679-10.326 0-6.358 2.938-11.63 8.018-11.63 2.637 0 4.476 1.141 5.4 3.532l-.978.327c-.757-2.229-2.325-3.524-4.136-3.524-4.356 0-5.708 6.024-5.708 12.778h.233c.572-2.867 2.937-4.636 5.479-4.636zm-5.393 6.891c0-4.208 2.422-6.282 4.793-6.282 2.771 0 4.516 2.318 4.516 6.282 0 3.507-1.517 6.282-4.545 6.282-2.856 0-4.764-2.775-4.764-6.282z"
        fill="#F5D171"
      />
      <path
        d="M139.768 49.891c4.165.272 7.789-2.01 7.789-6.032 0-4.348-4.116-5.585-6.93-5.707v-.163c2.412-.091 5.77-1.685 5.77-4.921 0-3.448-2.627-5.133-6.253-5.133-2.615 0-4.325 1.304-5.371 3.315l.884.38c.811-1.793 2.164-3.268 4.219-3.268 2.681 0 4.165 2.073 4.165 5.062 0 3.699-3.824 4.923-6.592 4.293v.707c3.03-.57 7.176 1.094 7.284 5.217.08 3.098-1.449 5.854-4.826 5.854-3.378 0-4.953-2.81-5.224-5.473l-1.081.326c.378 2.826 2.287 5.29 6.166 5.543z"
        fill="#F5D171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.487 36.413c3.4 0 6.459 2.12 6.459 6.522s-3.259 6.956-7.087 6.956c-4.342 0-7.68-4.562-7.68-10.326 0-6.358 2.938-11.63 8.019-11.63 2.637 0 4.476 1.141 5.4 3.532l-.978.327c-.757-2.229-2.326-3.524-4.136-3.524-4.356 0-5.708 6.024-5.708 12.778h.233c.571-2.867 2.936-4.636 5.478-4.636zm-5.392 6.891c0-4.208 2.422-6.282 4.793-6.282 2.77 0 4.516 2.318 4.516 6.282 0 3.507-1.517 6.282-4.545 6.282-2.856 0-4.764-2.775-4.764-6.282z"
        fill="#F5D171"
      />
      <path
        d="M91.811 38.88c-2.977-1.166-3.572-1.814-4.689-4.967-1.116 3.153-1.712 3.8-4.69 4.967 2.978 1.166 3.574 1.813 4.69 4.966 1.08-3.153 1.712-3.8 4.69-4.966zM0 38.974c0 6.984 5.617 10.917 11.475 10.917 4.265 0 8.214-2.956 9.241-7.086h-.378c-.974 3.75-4.76 6.69-8.408 6.69-4.704 0-8.804-3.441-8.804-10.582 0-7.14 3.775-10.582 8.48-10.582 4.296 0 7.596 3.112 8.137 8.496h1.406c-.753-5.979-5.084-8.892-9.999-8.892C5.293 27.935 0 31.99 0 38.974zM25.314 49.565h10.651v-.335h-7.828V28.219h-2.823v21.346zM52.34 28.327v12.96c0 5.215-2.578 7.869-5.553 7.869-3.678 0-5.158-2.063-5.158-7.003V28.327h-2.793v13.826c0 5.123 2.793 7.826 7.253 7.826 3.998 0 6.585-2.989 6.585-8.57V28.328h-.334z"
        fill="#F5D171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.677 44.053c0 3.904-3.37 5.642-7.8 5.642h-6.434V28.348h4.84c4.158 0 7.618 1.464 7.618 5.276 0 3.02-1.957 4.801-4.962 4.954v.278c3.55.091 6.738 1.538 6.738 5.197zm-10.032-15.37h-1.38v9.881h2.215c2.854 0 4.629-.975 4.629-4.94 0-3.476-1.973-4.94-5.464-4.94zm7.24 15.431c0-3.811-2.52-5.214-6.313-5.214h-2.306v10.46h2.913c3.43 0 5.705-1.19 5.705-5.246z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
