import * as React from "react";

function IconX(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 12C10.256 9.64 8.049 7 4 7V4c3.154 0 5.85 2.17 6.97 4.163.604 1.079.854 1.868.957 2.337h.146c.103-.469.353-1.258.958-2.337C14.149 6.17 16.846 4 20 4v3c-4.049 0-6.256 2.64-6.75 5 .494 2.36 2.701 5 6.75 5v3c-3.154 0-5.85-2.17-6.97-4.163-.604-1.079-.854-1.868-.957-2.337h-.146c-.103.469-.353 1.258-.958 2.337C9.85 17.83 7.154 20 4 20v-3c4.049 0 6.256-2.64 6.75-5z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default IconX;
