import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5c0 4.056-3.46 6-6 6v2c2.54 0 6 1.944 6 6h2.5c0-2.76-1.506-4.521-3.5-5.5-.315-.155-.654-.3-1-.436v-.137l13 .173v-2.2l-13 .173v-.137c.346-.136.685-.281 1-.436 1.994-.979 3.5-2.74 3.5-5.5H9z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
