import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 14.5c4.056 0 6 3.46 6 6h2c0-2.54 1.944-6 6-6V12c-2.76 0-4.521 1.506-5.5 3.5-.155.315-.3.654-.436 1h-.137l.173-13h-2.2l.173 13h-.137a13.19 13.19 0 00-.436-1C9.021 13.506 7.26 12 4.5 12v2.5z"
        fill="#F5D171"
      />
    </svg>
  );
}

export default SvgComponent;
