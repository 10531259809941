import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={257}
      height={22}
      viewBox="0 0 257 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.156 21.913c-5.738 0-10.712-4.566-10.712-10.957C69.444 4.566 74.418 0 80.156 0c5.737 0 10.712 4.565 10.712 10.956 0 6.392-4.975 10.957-10.712 10.957zm0-21.609c-4.853 0-7.569 3.774-7.569 10.652 0 6.878 2.716 10.652 7.569 10.652 4.852 0 7.569-3.774 7.569-10.652 0-6.878-2.717-10.652-7.57-10.652z"
        fill="#FFF8E5"
      />
      <path
        d="M95.225 21.608h10.71v-.335h-7.872V.304h-2.838v21.304zM139.111 13.239V.304h.336v13.057c0 5.57-2.602 8.552-6.622 8.552-4.485 0-7.293-2.698-7.293-7.81V.303h2.808v13.798c0 4.93 1.489 6.989 5.187 6.989 2.992 0 5.584-2.648 5.584-7.852zM160.683 21.63L159.486.872l-6.805 21.06h-.122L144.686.874 143.49 21.63h-.336L144.351.325h3.113l6.242 17.432L159.364.325h2.899l1.197 21.304h-2.777z"
        fill="#FFF8E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.463 11.778h2.532c3.083 0 4.303 1.156 5.188 3.774l1.16 3.287c.305.913.672 1.917 1.282 2.77h3.143c-.793-.792-1.281-1.888-1.648-2.983l-1.19-3.379c-.793-2.252-2.441-3.239-5.25-3.452v-.278c2.778-.06 5.845-1.504 5.845-5.552 0-4.109-3.785-5.66-8.24-5.66h-5.66v21.303h2.838v-9.83zm0-11.139h2.303c3.449 0 5.737 1.613 5.737 5.387 0 4.109-1.662 5.417-4.897 5.417h-3.143V.64z"
        fill="#FFF8E5"
      />
      <path
        d="M41.344 21.608h-2.808V.64h-5.751V.304h14.31V.64h-5.75v20.97zM18.535 21.608h12.1v-.335h-9.262V10.804h6.774v-.335h-6.774V.64h8.864V.304H18.535v21.304zM121.205 21.608h-12.099V.304h11.703V.64h-8.865v9.83h6.775v.335h-6.775v10.47h9.261v.334z"
        fill="#FFF8E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.263 12.265c4.486 0 8.362-1.583 8.362-5.996 0-4.382-3.906-5.965-8.362-5.965H0v21.304h2.838v-9.343h2.425zm-2.425-.335V.64h1.906c3.48 0 5.83 1.856 5.83 5.63 0 3.773-2.35 5.66-5.83 5.66H2.838z"
        fill="#FFF8E5"
      />
      <path
        d="M196.464 21.913c-5.89 0-11.538-3.925-11.538-10.896 0-6.97 5.322-11.017 11.212-11.017 4.941 0 9.297 2.908 10.054 8.874h-1.414c-.544-5.372-3.862-8.478-8.182-8.478-4.731 0-8.527 3.434-8.527 10.56 0 7.127 4.122 10.56 8.853 10.56 3.668 0 7.475-2.933 8.454-6.676h.381c-1.034 4.123-5.004 7.073-9.293 7.073zM221.09 21.587h-10.71V.283h2.838v20.97h7.872v.334zM237.557 13.326V.392h.335v13.056c0 5.57-2.601 8.552-6.621 8.552-4.486 0-7.294-2.698-7.294-7.81V.392h2.808V14.19c0 4.93 1.489 6.988 5.188 6.988 2.991 0 5.584-2.648 5.584-7.852z"
        fill="#FFF8E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.157 21.717c4.455 0 7.843-1.735 7.843-5.63 0-3.653-3.204-5.096-6.775-5.188v-.277c3.021-.153 4.989-1.931 4.989-4.944 0-3.804-3.479-5.265-7.66-5.265h-4.866v21.304h6.469zM245.526.747h1.387c3.51 0 5.493 1.461 5.493 4.93 0 3.957-1.784 4.931-4.653 4.931h-2.227V.748zm2.318 10.196c3.815 0 6.348 1.4 6.348 5.204 0 4.048-2.289 5.235-5.737 5.235h-2.929V10.943h2.318z"
        fill="#FFF8E5"
      />
      <path
        d="M174.303 5.966c1.123 3.147 1.722 3.793 4.715 4.957-2.993 1.164-3.63 1.81-4.715 4.956-1.123-3.146-1.721-3.792-4.715-4.956 2.994-1.164 3.592-1.81 4.715-4.957z"
        fill="#FFF8E5"
      />
    </svg>
  );
}

export default SvgComponent;
